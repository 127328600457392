import {
  Agent,
  Manifestation,
  Subject,
  Title,
  UniformTitleMusic,
  Work,
  formatIsbdTitle,
} from "@biblioteksentralen/cordata";
import { formaterDato, slugifyString } from "@biblioteksentralen/utils";
import {
  TemplateCampaign,
  camelToKebabCase,
  isDigitalLibraryCustomService,
  isDigitalLibraryService,
  isTemplateCampaign,
  templateCampaignSlugs,
} from "@libry-content/common";
import { LocalizedField } from "@libry-content/localization";
import {
  CustomCampaign,
  Documents,
  Employee,
  Library,
  List,
  Recommendation,
  Service,
  StaticPage,
} from "@libry-content/types";
import { GetStaticPropsContext } from "next";
import { ResolvedEvent } from "../components/arrangement/sanityQuery";
import { isEventOccurrence } from "../components/arrangement/utils";
import { ResolvedDigitalLibraryServiceSummary } from "../components/digitalLibrary/sanityQuery";
import { CollectionExpressionEntryWithWork } from "../searchAndCollection/cordata/collections";
import { CordataSearchFacetTerm } from "../searchAndCollection/types";

type BaseUrlDetails<T extends Pick<Documents, "_id" | "_type">, B extends keyof T> = Pick<T, "_id" | "_type" | B>;

type EventUrlDetails = Pick<ResolvedEvent, "_id" | "_type" | "title" | "startDate">;

type DigitalLibraryServiceUrlDetails = Pick<ResolvedDigitalLibraryServiceSummary, "_id" | "_type" | "name" | "code">;

export type CordataWorkUrlDetails = Pick<Work, "id" | "type" | "title">;

const isCordataWorkUrlDetails = (doc: UrlDetails): doc is CordataWorkUrlDetails => (doc as any)?.["type"] === "Work";

export type CordataAgentUrlDetails = Pick<Agent, "id" | "type" | "name" | "identifiers">;

const isCordataAgentUrlDetails = (doc: UrlDetails): doc is CordataAgentUrlDetails =>
  (doc as any)?.["type"] === "Person" || (doc as any)?.["type"] === "CollectiveAgent";

export type CordataSubjectUrlDetails = Pick<Subject, "id" | "type" | "name">;

const isCordataSubjectUrlDetails = (doc: UrlDetails): doc is CordataSubjectUrlDetails =>
  (doc as any)?.["type"] === "Subject";

export type FacetTermUrlDetails = Pick<CordataSearchFacetTerm, "value" | "name"> & { type: string };

const isFacetTermUrlDetails = (doc: UrlDetails): doc is FacetTermUrlDetails => (doc as any)?.["type"] === "FacetTerm";

export type CollectionUrlDetails = Pick<
  CollectionExpressionEntryWithWork["collection"],
  "type" | "title" | "workExpressed"
>;

const isCollectionUrlDetails = (doc: UrlDetails): doc is CollectionUrlDetails =>
  (doc as any)?.["type"] === "CollectionExpression";

export type CordataManifestationUrlDetails = Pick<Manifestation, "id" | "type" | "title"> & { workId: string };

const isCordataManifestationUrlDetails = (doc: UrlDetails): doc is CordataManifestationUrlDetails =>
  (doc as any)?.["type"] === "Manifestation";

const isDigitalLibraryServiceUrlDetails = (doc: UrlDetails): doc is DigitalLibraryServiceUrlDetails =>
  isDigitalLibraryService(doc);

type UrlDetails =
  | EventUrlDetails
  | BaseUrlDetails<Library, "slug">
  | BaseUrlDetails<Employee, "name">
  | BaseUrlDetails<List, "title">
  | BaseUrlDetails<Recommendation, "publication">
  | BaseUrlDetails<Service, "title">
  | BaseUrlDetails<StaticPage, "title">
  | DigitalLibraryServiceUrlDetails
  | CordataWorkUrlDetails
  | CordataManifestationUrlDetails
  | CordataAgentUrlDetails
  | CordataSubjectUrlDetails
  | FacetTermUrlDetails
  | CollectionUrlDetails
  | BaseUrlDetails<CustomCampaign, "title">
  | BaseUrlDetails<TemplateCampaign, "_id">;

export const getDigitalLibraryServicePathSegment = (service: DigitalLibraryServiceUrlDetails): string => {
  if (isDigitalLibraryCustomService(service)) {
    const slug = slugifyString(translate(service.name));
    return `${slug}_${service?._id}`;
  }

  if (!service?.code) {
    throw new Error(`Could not generate path for digital library service ${JSON.stringify(service)}`);
  }

  return camelToKebabCase(service.code);
};

const titleIsUniformTitleMusic = (title: Title | UniformTitleMusic): title is UniformTitleMusic =>
  title.titleType === "uniform_title_music";

export const getPath = (doc: UrlDetails) => {
  if (isCordataWorkUrlDetails(doc)) {
    return `/samling/verk/${slugifyString(
      titleIsUniformTitleMusic(doc.title) ? formatIsbdTitle(doc) : doc.title.mainTitle
    )}_${doc.id}`;
  }

  if (isCordataManifestationUrlDetails(doc)) {
    return `/samling/verk/${doc.workId}/utgave/${slugifyString(doc.title.mainTitle)}_${doc.id}`;
  }

  if (isCordataAgentUrlDetails(doc)) {
    const { id, identifiers, name } = doc;
    // Pass norafId if it exists to fetch wikidata and snldata
    return identifiers.norafId
      ? `/samling/personer/${slugifyString(translate(name))}_b${id}-n${identifiers.norafId}`
      : `/samling/personer/${slugifyString(translate(name))}_b${id}`;
  }

  if (isCordataSubjectUrlDetails(doc)) {
    return `/samling/emner/${slugifyString(translate(doc.name))}_${doc.id}`;
  }

  if (isFacetTermUrlDetails(doc)) {
    return `/samling/emner/${slugifyString(doc.name)}_${doc.value}`;
  }

  if (isDigitalLibraryServiceUrlDetails(doc)) {
    return `/digitalt-bibliotek/${getDigitalLibraryServicePathSegment(doc)}`;
  }

  if (isTemplateCampaign(doc)) {
    return `/kampanje/${templateCampaignSlugs[doc._type]}`;
  }

  if (isCollectionUrlDetails(doc)) {
    return `/samling/serie/${slugifyString(doc.title.mainTitle)}_${doc.workExpressed.id}`;
  }

  switch (doc._type) {
    case "resolvedRepeatedEventOccurence":
    case "event":
      return `/arrangementer/${getEventHumanReadablePart(doc)}_${doc?._id}`;
    case "library":
      return `/bibliotek/${doc?.slug}`;
    case "employee":
      return `/ansatte/${slugifyString(doc.name)}_${doc._id}`;
    case "list":
      return `/lister/${slugifyString(translate(doc.title))}_${doc._id}`;
    case "recommendation":
      return `/anbefalinger/${slugifyString(doc.publication?.title)}_${doc._id}`;
    case "service":
      return `/tjenester/${slugifyString(translate(doc.title))}_${doc._id}`;
    case "staticPage":
      return `/info/${slugifyString(translate(doc.title))}_${doc._id}`;
    case "customCampaign":
      return `/kampanje/${slugifyString(translate(doc.title))}_${doc._id}`;
    default: {
      const message = `Could not generate path for unkown document type: ${doc._type}`;
      console.error(message, { doc });
      throw new Error(message);
    }
  }
};

// Denne tar ikke hensyn til gjeldende språk, men funker for øyeblikket fordi nettsteder kun har nynorsk ELLER bokmål. Om vi får støtte for at man kan begge deler samtidig bør denne kanskje ta hensyn til gjeldende språk.
const translate = (item?: LocalizedField<string>) => item?.nn ?? item?.nb;

const getEventHumanReadablePart = (event: EventUrlDetails) => {
  const title = slugifyString(translate(event.title));
  const date = formaterDato(isEventOccurrence(event) ? event.occurrence?.date : event.startDate, "dd-MMM").replace(
    ".",
    ""
  );

  if (!date) return title;

  return `${title}-${date}`;
};

export const extractId = (ctx: GetStaticPropsContext, paramKey: string): string => {
  if (!ctx.params) {
    throw new Error(`Could not extract url param for ${paramKey}`);
  }

  const temp = ctx.params[paramKey];

  if (typeof temp !== "string") {
    throw new Error(`Could not extract url param for ${paramKey}`);
  }

  return temp.split("_").slice(-1)?.[0] ?? "";
};
